// My libs
import CustomColors from "./CustomColors";
import WebConstraint from "./Components/WebConstraint";
import Footer from "./Footer";
import { ShowOn } from "./util/MediaQuery";

// Support pages
import AboutSchmick from "./SupportPages/AboutSchmick";
import AboutKarma from "./SupportPages/AboutKarma";
import AboutSchmickEmails from "./SupportPages/AboutSchmickEmails";
import AboutSchmickOfTheDay from "./SupportPages/AboutSchmickOfTheDay";
import AboutPosts from "./SupportPages/AboutPosts";
import AboutLogin from "./SupportPages/AboutLogin";
import AboutReportUser from "./SupportPages/AboutReportUser";
import AboutBlockPost from "./SupportPages/AboutBlockPost";
import AboutBlockUserFromPost from "./SupportPages/AboutBlockUserFromPost";
import WhereToContactUs from "./SupportPages/WhereToContactUs";
import AboutUserNotifications from "./SupportPages/AboutUserNotifications";
import AboutProfile from "./SupportPages/AboutProfile";
import AboutProfilePostPrivacy from "./SupportPages/AboutProfilePostPrivacy";
import AboutProfilePrivacy from "./SupportPages/AboutProfilePrivacy";
import AboutProfileBlock from "./SupportPages/AboutProfileBlock";
import AboutProfileUnblock from "./SupportPages/AboutProfileUnblock";
import AboutProfileReport from "./SupportPages/AboutProfileReport";
import AboutAddProfiles from "./SupportPages/AboutAddProfiles";
import AboutProfileDm from "./SupportPages/AboutProfileDm";
import AboutProfileWallPosting from "./SupportPages/AboutProfileWallPosting";
import AboutProfileHomeFeed from "./SupportPages/AboutProfileHomeFeed";
import AboutProfileFollowRequests from "./SupportPages/AboutProfileFollowRequests";
import AboutProfileSegmentation from "./SupportPages/AboutProfileSegmentation";
import AboutProfilePostPrivacyToggle from "./SupportPages/AboutProfilePostPrivacyToggle";

// Packages
import React from "react";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";

function PanelSection(props) {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={
            <Typography style={{ fontWeight: "bold", fontSize: "40px" }}>
              {props.title ?? "Title"}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.children}
        </List>
      </Collapse>
    </div>
  );
}

class PanelButton extends React.Component {
  constructor(props) {
    super(props);

    console.assert(
      props.title !== undefined,
      "PanelButton title must not be undefined.",
    );
    console.assert(
      props.pageKey !== undefined,
      "PanelButton pageKey must not be undefined.",
    );
    console.assert(
      props.onClick !== undefined,
      "PanelButton onClick must not be undefined.",
    );
    this.state = {
      pageKey: props.pageKey,
      title: props.title,
      isHover: false,
    };
  }

  render() {
    return (
      <div
        style={{
          marginLeft: "10px",
        }}
      >
        <ListItem
          button
          onClick={() => {
            console.log("Panel Button: ", this.state.pageKey);
            this.props.onClick(this.state.pageKey);
          }}
        >
          <ListItemText
            primary={
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {this.props.title}
              </Typography>
            }
          />
          <ArrowRight style={{ marginRight: "10px", marginLeft: "10px" }} />
        </ListItem>
      </div>
    );
  }
}

class SidePanel extends React.Component {
  constructor(props) {
    super(props);
    console.assert(
      props.onClick !== undefined,
      "SidePanel onClick must not be undefined.",
    );

    console.assert(
      props.width !== undefined,
      "SidePanel width must not be undefined.",
    );

    this.state = {
      page: "About",
    };
  }

  render() {
    return (
      <div>
        <List
          style={{
            padding: "0px 0px 0px",
            position: this.props.position ?? "fixed",
            overflow: "scroll",
            height: "100%",
            width: this.props.width,
            backgroundColor: CustomColors.slightBlueWhite,
          }}
        >
          <Box sx={{ height: 80 }} />
          <PanelSection title="About">
            <PanelButton
              title="What is Schmick?"
              pageKey="about-schmick"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="Where do I contact Schmick?"
              pageKey="contact-us"
              onClick={(page) => this.props.onClick(page)}
            />
          </PanelSection>
          <PanelSection title="Post">
            <PanelButton
              title="What is karma score?"
              pageKey="karma"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="What is Schmick of the day?"
              pageKey="schmick-of-the-day"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do posts work?"
              pageKey="about-post"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="What is the difference between public and private profile posts?"
              pageKey="profile-post-privacy"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How can I include more people in my profile posts?"
              pageKey="profile-add-profiles"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do I make a public profile post private?"
              pageKey="profile-post-privacy-toggle"
              onClick={(page) => this.props.onClick(page)}
            />
          </PanelSection>

          <PanelSection title="User">
            <PanelButton
              title="How do I sign in as an older user?"
              pageKey="login"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How can I report a user?"
              pageKey="report-user"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do I control notifications?"
              pageKey="notifications-user"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="What should I do if Schmick isn't sending me emails?"
              pageKey="schmick-emails"
              onClick={(page) => this.props.onClick(page)}
            />
          </PanelSection>

          <PanelSection title="Block">
            <PanelButton
              title="How can I block posts from my feed?"
              pageKey="block-post"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How can I block anon users from my posts?"
              pageKey="block-user"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do I block a profile?"
              pageKey="profile-block"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do I unblock a profile?"
              pageKey="profile-unblock"
              onClick={(page) => this.props.onClick(page)}
            />
          </PanelSection>

          <PanelSection title="Profile">
            <PanelButton
              title="What is a profile wall?"
              pageKey="about-profile"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="What is the difference between profile privacy and profile post privacy?"
              pageKey="profile-privacy-diff"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do I report a profile?"
              pageKey="profile-report"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How can I directly message a profile or multiple profiles?"
              pageKey="profile-dm"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do I post on someone else's profile wall?"
              pageKey="profile-wall-posting"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How can I see my friends profile posts on my home feed?"
              pageKey="profile-home-feed"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="How do follow requests work?"
              pageKey="profile-follow-requests"
              onClick={(page) => this.props.onClick(page)}
            />
            <PanelButton
              title="Will anonymous users be able to interact with profile-based content?"
              pageKey="profile-content-segmentation"
              onClick={(page) => this.props.onClick(page)}
            />
          </PanelSection>
        </List>
      </div>
    );
  }
}

export default class SupportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "about-schmick",
      sidePanelWidth: "300px",
    };
  }

  onSideClick(page) {
    console.log("Current support page: ", page);
    this.setState({ page: page });
  }

  pageContent() {
    if (this.state.page === "about-schmick") {
      return <AboutSchmick />;
    } else if (this.state.page === "contact-us") {
      return <WhereToContactUs />;
    } else if (this.state.page === "schmick-emails") {
      return <AboutSchmickEmails />;
    } else if (this.state.page === "karma") {
      return <AboutKarma />;
    } else if (this.state.page === "schmick-of-the-day") {
      return <AboutSchmickOfTheDay />;
    } else if (this.state.page === "about-post") {
      return <AboutPosts />;
    } else if (this.state.page === "login") {
      return <AboutLogin />;
    } else if (this.state.page === "report-user") {
      return <AboutReportUser />;
    } else if (this.state.page === "notifications-user") {
      return <AboutUserNotifications />;
    } else if (this.state.page === "block-post") {
      return <AboutBlockPost />;
    } else if (this.state.page === "block-user") {
      return <AboutBlockUserFromPost />;
    } else if (this.state.page === "about-profile") {
      return <AboutProfile />;
    } else if (this.state.page === "profile-privacy-diff") {
      return <AboutProfilePrivacy />;
    } else if (this.state.page === "profile-post-privacy") {
      return <AboutProfilePostPrivacy />;
    } else if (this.state.page === "profile-post-privacy-toggle") {
      return <AboutProfilePostPrivacyToggle />;
    } else if (this.state.page === "profile-block") {
      return <AboutProfileBlock />;
    } else if (this.state.page === "profile-unblock") {
      return <AboutProfileUnblock />;
    } else if (this.state.page === "profile-report") {
      return <AboutProfileReport />;
    } else if (this.state.page === "profile-tag") {
      return <AboutAddProfiles />;
    } else if (this.state.page === "profile-dm") {
      return <AboutProfileDm />;
    } else if (this.state.page === "profile-wall-posting") {
      return <AboutProfileWallPosting />;
    } else if (this.state.page === "profile-home-feed") {
      return <AboutProfileHomeFeed />;
    } else if (this.state.page === "profile-follow-requests") {
      return <AboutProfileFollowRequests />;
    } else if (this.state.page === "profile-content-segmentation") {
      return <AboutProfileSegmentation />;
    }
    return <AboutSchmick />;
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  panel(options) {
    return (
      <Grid item style={{ height: "100%" }}>
        <ShowOn
          desktop={<div style={{ width: this.state.sidePanelWidth }} />}
        />
        <SidePanel
          position={options?.position}
          width={options?.width ?? this.state.sidePanelWidth}
          onClick={(curPage) => {
            this.onSideClick(curPage);
            this.scrollToTop();
          }}
        />
      </Grid>
    );
  }

  content(options) {
    return (
      <Grid item xs>
        <Grid
          style={{
            maxWidth: "100%",
            height: "100%",
            minHeight: options?.minHeight ?? "100vh",
          }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <WebConstraint>
            <div
              style={{
                marginLeft: "50px",
                marginBottom: "100px",
                marginRight: "50px",
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              />
              {this.pageContent()}
            </div>
          </WebConstraint>

          <ShowOn
            desktop={<div style={{ width: this.state.sidePanelWidth }} />}
          />
        </Grid>

        <ShowOn desktop={<Footer />} />
      </Grid>
    );
  }

  render() {
    return (
      <Grid
        style={{ width: "100%", height: "100%" }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <ShowOn desktop={this.panel()} />
        <ShowOn
          desktop={this.content()}
          mobile={this.content({ minHeight: "0px" })}
        />
        <ShowOn
          mobile={this.panel({
            position: "static",
            width: "100vw",
            marginTop: "0px",
          })}
        />
        <ShowOn mobile={<Footer />} />
      </Grid>
    );
  }
}
