import CustomColors from "./CustomColors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WebConstraint from "./Components/WebConstraint";
import Box from "@mui/material/Box";

function contentMargin() {
  return {
    marginTop: "20px",
    marginBottom: "20px",
    marginRight: "30px",
    marginLeft: "30px",
  };
}

function Copyright() {
  return (
    <div style={contentMargin()}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: CustomColors.white,
          }}
        >
          Copyright © 2024 Schmick Inc.
        </Typography>
      </Grid>
    </div>
  );
}

function FooterContent() {
  const pad = {
    paddingRight: "30px",
  };

  const style = {
    fontWeight: "bold",
    fontSize: "14px",
    color: CustomColors.white,
  };

  return (
    <div style={contentMargin()}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item style={pad}>
          <a href="https://schmick.app/terms-and-conditions.html">
            <Typography style={style}>Term of Use</Typography>
          </a>
        </Grid>
        <Grid item style={pad}>
          <a href="https://schmick.app/privacy-policy.html">
            <Typography style={style}>Privacy Policy</Typography>
          </a>
        </Grid>
        <Grid item style={pad}>
          <a href="https://share.hsforms.com/1ue4A4HdsTBmInRgJ9pLGIAq4xuu">
            <Typography style={style}>Contact Us</Typography>
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default function Footer() {
  return (
    <Box style={{ backgroundColor: CustomColors.superDarkNavy2 }}>
      <WebConstraint>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <FooterContent />
          </Grid>
          <Grid item>
            <Copyright />
          </Grid>
        </Grid>
      </WebConstraint>
    </Box>
  );
}
