import Title from "../Title";
import Paragraph from "../Paragraph";

export default function WhereToContactUs() {
  return (
    <div>
      <Title text="Where to contact Schmick?" />
      <Paragraph>You can contact us by filling the form below.</Paragraph>
      <iframe
        title="Contact form"
        src="https://share.hsforms.com/1ue4A4HdsTBmInRgJ9pLGIAq4xuu"
        width="100%"
        height="720px"
        style={{ border: "none", marginTop: "30px" }}
      ></iframe>
    </div>
  );
}
